import axios from "axios";
import store from "../store/index";
import router from "../router/index";

export default {
  async createOne(url, data) {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          axios.defaults.headers.common["Authorization"] = "Bearer " + store.getters.getToken;
          const res = await axios.post(url, data);
          resolve(res);
        } catch (err) {
          let error = await this.validateRequest(err);
          reject(error);
        }
      })();
    });
  },
  async get(url) {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          axios.defaults.headers.common["Authorization"] = "Bearer " + store.getters.getToken;
          const res = await axios(url);
          resolve(res);
        } catch (err) {
          let error = await this.validateRequest(err);
          reject(error);
        }
      })();
    });
  },
  async updateOne(url, data) {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          axios.defaults.headers.common["Authorization"] = "Bearer " + store.getters.getToken;
          const res = await axios({ method: "patch", url, data });
          resolve(res);
        } catch (err) {
          let error = await this.validateRequest(err);
          reject(error);
        }
      })();
    });
  },
  async updateMany(url, data) {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          axios.defaults.headers.common["Authorization"] = "Bearer " + store.getters.getToken;
          const res = await axios({ method: "patch", url, data });
          resolve(res);
        } catch (err) {
          let error = await this.validateRequest(err);
          reject(error);
        }
      })();
    });
  },
  async delete(url, data) {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          axios.defaults.headers.common["Authorization"] = "Bearer " + store.getters.getToken;
          const res = await axios({
            method: "delete",
            url,
            data,
          });
          resolve(res);
        } catch (err) {
          let error = await this.validateRequest(err);
          reject(error);
        }
      })();
    });
  },
  async validateRequest(err) {
    console.log(err);
    const error = err?.response?.data?.message || "";

    if (error.includes("email_1 dup key")) return "Email on jo käytössä";
    if (error.includes("productId_1_createdBy_1 dup key")) return "Tuotetunnus on jo käytössä";

    if (error.startsWith("jwt expired")) {
      await store.dispatch("deleteToken");
      router.push("/login");
      return "Istuntosi on vanhentunut";
    }

    if (error.startsWith("JWT -") || err.response.data.error.name == "JsonWebTokenError") {
      await store.dispatch("deleteToken");
      router.push("/login");
      return "Käyttäjän tunnistaminen ei onnistunut, kirjaudu uudelleen";
    }

    if (error.startsWith("ROLE -") || err.status == 401) {
      await store.dispatch("deleteToken");
      router.push("/login");
      return "Et ole oikeutettu kyseiseen toimenpiteeseen";
    }

    return error;
  },
};
