<template>
  <v-container>
    <h1 class="mt-5">Maksumuistuksen saavat asiakkaat</h1>

    <v-row>
      <v-col>
        <div class="customer-wrapper">
          <v-treeview
            v-model="selected"
            :items="customers"
            selection-type="independent"
            selectable
            return-object
            open-all
          ></v-treeview>
        </div>
      </v-col>
      <v-divider vertical></v-divider>
      <v-col class="pa-6" cols="6">
        <div class="customer-list-wrapper">
          <!--  No customers -->
          <template v-if="!selected.length">Ei valittuja asiakkaita</template>
          <!-- Selected customers -->
          <template v-else>
            <div v-for="customer in selected" :key="customer.id">{{ customer.name }}</div>
          </template>
        </div>
      </v-col>
    </v-row>
    <v-btn class="mt-5" color="success" @click="submit">Tallenna</v-btn>
    <!-- Hr -->
    <v-row class="mt-5">
      <v-col>
        <hr />
      </v-col>
    </v-row>
    <!-- Reminders -->
    <v-row>
      <v-col>
        <h2>Maksumuistutukset</h2>
        <v-btn class="mt-5" color="info" @click="sendReminders"> Lähetä maksumuistukset</v-btn>
      </v-col>
    </v-row>

    <!--  Sent invoices -->
    <v-row v-if="invoices.length == 0">
      <v-col>
        <p>Ei lähetettyjä muistutuksia</p>
      </v-col>
    </v-row>
    <v-row v-if="invoices.length > 0">
      <v-col>
        <h2>Lähetetyt muistutukset</h2>
        <div v-for="(invoice, index) in invoices" :key="index">
          <h4>{{ index + 1 }}.</h4>
          <p>Saaja: {{ invoice.customer.name }}</p>
          <p>Laskun numero: {{ invoice.invoiceNumber }}</p>
          <hr />
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import mixins from "../../mixins/mixins";
import axiosMethods from "../../mixins/axios";

export default {
  title: "Maksumuistutukset",
  mixins: [mixins],
  data() {
    return {
      invoices: [],
      selected: [],
      customers: [],
      required: [(v) => !!v || "Pakollinen kenttä"],
    };
  },
  async created() {
    let res1 = await axiosMethods.get("/api/v1/customer/getAll");
    this.customers = res1.data.data;

    // Get selected members/customers
    let res2 = await axiosMethods.get("/api/v1/customer/getReminderCustomers");
    this.selected = res2.data.customers;
  },
  methods: {
    async submit() {
      try {
        // get ids
        let ids = this.selected.map((el) => {
          return el._id;
        });

        // Request
        await axiosMethods.createOne("/api/v1/customer/saveReminderCustomers", {
          ids,
        });

        this.showPopup("Maksumuistutuksen saavat asiakkaat tallennettu", "success");
      } catch (err) {
        this.showPopup(err, "error");
      }
    },
    async sendReminders() {
      try {
        // Request
        let res = await axiosMethods.get("/api/v1/invoice/checkIsLateInvoices");

        let invoices = res.data.invoicesSent;

        if (invoices.length > 0) {
          this.showPopup("Maksumuistutukset lähetetty", "success");
          this.invoices = invoices;
        } else {
          this.showPopup(
            "Myöhässä olevia laskuja tai muistuttamattomia laskuja ei löytynyt.",
            "success"
          );
        }
      } catch (err) {
        this.showPopup(err, "error");
      }
    },
  },
};
</script>

<style scoped>
.customer-wrapper {
  margin-left: -35px;
  max-height: 500px;
  overflow: auto;
}
.customer-list-wrapper {
  max-height: 500px;
  overflow: auto;
}
</style>
